import * as React from "react"
import {Helmet} from "react-helmet";
import {useStaticQuery, graphql} from "gatsby"


export default function Header(props) {
    const {site} = useStaticQuery(
        graphql`
      query {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `
    )

    const metaDescription = props.description || site.siteMetadata.description
    const defaultTitle = site.siteMetadata?.title
    const lang = process.env.LANG === "FR" ? "fr_CA" : "en";
    const meta = {};
    if (props.lang === "FR") {
        return (<>
            <Helmet bodyAttributes={{class: props.bodyClasses}}
                    htmlAttributes={{
                        lang,
                    }}
                    title={props.title ? props.title : defaultTitle}
                    titleTemplate={defaultTitle ? `%s` : null}
                    meta={[
                        {
                            name: `description`,
                            content: metaDescription,
                        },
                        {
                            property: `og:title`,
                            content: props.title ? props.title : defaultTitle,
                        },
                        {
                            property: `og:description`,
                            content: metaDescription,
                        },
                        {
                            property: `og:type`,
                            content: `website`,
                        },
                        {
                            name: `twitter:card`,
                            content: `summary`,
                        },
                        {
                            name: `twitter:creator`,
                            content: site.siteMetadata?.author || ``,
                        },
                        {
                            name: `twitter:title`,
                            content: props.title ? props.title : defaultTitle,
                        },
                        {
                            name: `twitter:description`,
                            content: metaDescription,
                        },
                    ].concat(meta)}>
                <link href="/GCWeb/assets/favicon.ico" rel="icon" type="image/x-icon"/>
                <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.8.1/css/all.css"
                      integrity="sha384-50oBUHEmvpQ+1lW4y57PTFmhCaXp0ML5d60M1M7uH2+nqUivzIebhndOJK28anvf"
                      crossOrigin="anonymous"/>
                <link rel="stylesheet" href="/GCWeb/css/theme.min.css"/>
                <link rel="stylesheet" href="/GCWeb/css/messages.min.css"/>
                {/*<noscript>*/}
                {/*    '<link rel="stylesheet" href="./wet-boew/css/noscript.min.css" />'*/}
                {/*</noscript>*/}
            </Helmet>
            {!props.hidden ?
                <header>
                    <div id="wb-bnr" className="container">
                        <div className="row">
                            <section id="wb-lng" className="col-xs-3 col-sm-12 pull-right text-right">
                                <h2 className="wb-inv">Sélection de la langue</h2>
                                <ul className="list-inline mrgn-bttm-0">
                                    <li>
                                        <a lang="en" hrefLang="en" id={"lang_link"} href={props.langLink}>
                                            <span className="hidden-xs">English</span>
                                            <abbr title="English"
                                                  className="visible-xs h3 mrgn-tp-sm mrgn-bttm-0 text-uppercase">en</abbr>
                                        </a>
                                    </li>
                                </ul>
                            </section>
                            <div className="brand col-xs-9 col-sm-5 col-md-4" property="publisher"
                                 resource="#wb-publisher"
                                 typeof="GovernmentOrganization">
                                <a href="https://www.canada.ca/en.html" property="url">
                                    <img src="/GCWeb/assets/sig-blk-fr.svg" alt="Government of Canada"
                                         property="logo"/>
                                    <span className="wb-inv"> / <span lang="en">Government of Canada</span></span>
                                </a>
                                <meta property="name" content="Gouvernement du Canada"/>
                                <meta property="areaServed" typeof="Country" content="Canada"/>
                                <link property="logo" href="/GCWeb/assets/wmms-blk.svg"/>
                            </div>
                        </div>
                    </div>
                    <nav className="gcweb-menu" typeof="SiteNavigationElement">
                        <div className="container">
                            <h2 className="wb-inv">Menu</h2>
                            <button type="button" aria-haspopup="true" aria-expanded="false">Menu<span
                                className="wb-inv"> principal</span>
                                <span className="expicon glyphicon glyphicon-chevron-down"/></button>
                            <ul role="menu" aria-orientation="vertical">
                                 <li role="presentation">
                                    <a role="menuitem" href="/fr/">Accueil</a>
                                </li>
                                <li role="presentation">
                                    <a role="menuitem" href="/fr/a-propos/">À Propos</a>
                                </li>
                                <li role="presentation">
                                    <a role="menuitem" href="/fr/ressources/">Ressources</a>
                                </li>
                            </ul>
                        </div>
                    </nav>
                    {props.disableBreadcrumbs === true ? "" :
                        <nav id="wb-bc" property="breadcrumb">
                            <h2>Vous êtes ici :</h2>
                            <div className="container">
                                <ol className="breadcrumb">
                                    <li><a href="https://www.canada.ca/fr.html">Canada.ca</a></li>
                                    <li><a href="https://www.international.gc.ca/global-affairs-affaires-mondiales/home-accueil.aspx?lang=fra">Affaires mondiales Canada</a></li>
                                    <li><a href="https://www.international.gc.ca/global-affairs-affaires-mondiales/corporate-ministere/contact-contactez/jobs-emplois.aspx?lang=fra">Possibilités d’emploi</a></li>
                                    <li><a href="/fr/">Opportunités d’emploi dans nos bureaux du Canada à l’étranger</a></li>
                                    {props.breadcrumbs ? props.breadcrumbs.forEach((crumb) => {
                                        if (crumb.hasOwnProperty('text') && crumb.hasOwnProperty("url")) {
                                            return (<li><a href={crumb.url}>{crumb.text}</a></li>)
                                        }
                                    }) : ""}

                                </ol>
                            </div>
                        </nav>}
                </header>
                : ""}
            {!props.hidden ?
                <div className={"w100 hr-line"}/> : ""}

        </>)
    } else {
        return (<>
            <Helmet bodyAttributes={{class: props.bodyClasses}}
                    htmlAttributes={{
                        lang,
                    }}
                    title={props.title ? props.title : defaultTitle}
                    titleTemplate={defaultTitle ? `%s` : null}
                    meta={[
                        {
                            name: `description`,
                            content: metaDescription,
                        },
                        {
                            property: `og:title`,
                            content: props.title ? props.title : defaultTitle,
                        },
                        {
                            property: `og:description`,
                            content: metaDescription,
                        },
                        {
                            property: `og:type`,
                            content: `website`,
                        },
                        {
                            name: `twitter:card`,
                            content: `summary`,
                        },
                        {
                            name: `twitter:creator`,
                            content: site.siteMetadata?.author || ``,
                        },
                        {
                            name: `twitter:title`,
                            content: props.title ? props.title : defaultTitle,
                        },
                        {
                            name: `twitter:description`,
                            content: metaDescription,
                        },
                    ].concat(meta)}>
                <link href="/GCWeb/assets/favicon.ico" rel="icon" type="image/x-icon"/>
                <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.8.1/css/all.css"
                      integrity="sha384-50oBUHEmvpQ+1lW4y57PTFmhCaXp0ML5d60M1M7uH2+nqUivzIebhndOJK28anvf"
                      crossOrigin="anonymous"/>
                <link rel="stylesheet" href="/GCWeb/css/theme.min.css"/>
                <link rel="stylesheet" href="/GCWeb/css/messages.min.css"/>
                {/*<noscript>*/}
                {/*    '<link rel="stylesheet" href="./wet-boew/css/noscript.min.css" />'*/}
                {/*</noscript>*/}
            </Helmet>
            {!props.hidden ?
                <header>
                    <div id="wb-bnr" className="container">
                        <div className="row">
                            <section id="wb-lng" className="col-xs-3 col-sm-12 pull-right text-right">
                                <h2 className="wb-inv">Language selection</h2>
                                <ul className="list-inline mrgn-bttm-0">
                                    <li>
                                        <a lang="fr" hreflang="fr"  id={"lang_link"} href={props.langLink}>
                                            <span className="hidden-xs">Français</span>
                                            <abbr title="Français"
                                                  className="visible-xs h3 mrgn-tp-sm mrgn-bttm-0 text-uppercase">fr</abbr>
                                        </a>
                                    </li>
                                </ul>
                            </section>
                            <div className="brand col-xs-9 col-sm-5 col-md-4" property="publisher"
                                 resource="#wb-publisher"
                                 typeof="GovernmentOrganization">
                                <a href="https://www.canada.ca/en.html" property="url">
                                    <img src="/GCWeb/assets/sig-blk-en.svg" alt="Government of Canada"
                                         property="logo"/>
                                    <span className="wb-inv"> / <span lang="fr">Gouvernement du Canada</span></span>
                                </a>
                                <meta property="name" content="Government of Canada"/>
                                <meta property="areaServed" typeof="Country" content="Canada"/>
                                <link property="logo" href="/GCWeb/assets/wmms-blk.svg"/>
                            </div>
                        </div>
                    </div>
                    <nav className="gcweb-menu" typeof="SiteNavigationElement">
                        <div className="container">
                            <h2 className="wb-inv">Menu</h2>
                            <button type="button" aria-haspopup="true" aria-expanded="false"><span
                                className="wb-inv">Main </span>Menu <span
                                className="expicon glyphicon glyphicon-chevron-down"/></button>
                            <ul role="menu" aria-orientation="vertical">
                                 <li role="presentation">
                                    <a role="menuitem" href="/en/">Home</a>
                                </li>
                                <li role="presentation">
                                    <a role="menuitem" href="/en/about/">About Us</a>
                                </li>
                                <li role="presentation">
                                    <a role="menuitem" href="/en/resources/">Resources</a>
                                </li>
                            </ul>
                        </div>
                    </nav>
                    {props.disableBreadcrumbs === true ? "" :
                        <nav id="wb-bc" property="breadcrumb">
                            <h2>You are here:</h2>
                            <div className="container">
                                <ol className="breadcrumb">
                                    <li><a href="https://www.canada.ca/en.html">Canada.ca</a></li>
                                    <li><a href={"https://www.international.gc.ca/global-affairs-affaires-mondiales/home-accueil.aspx?lang=eng"}>Global Affairs Canada</a></li>
                                    <li><a href={"https://www.international.gc.ca/global-affairs-affaires-mondiales/corporate-ministere/contact-contactez/jobs-emplois.aspx?lang=eng"}>Job opportunities</a></li>
                                    <li><a href={"/en/"}>Job Opportunities at our Canadian offices abroad</a></li>
                                    {props.breadcrumbs ? props.breadcrumbs.forEach((crumb) => {
                                        if (crumb.hasOwnProperty('text') && crumb.hasOwnProperty("url")) {
                                            return (<li><a href={crumb.url}>{crumb.text}</a></li>)
                                        }
                                    }) : ""}
                                </ol>
                            </div>
                        </nav>}
                </header>
                : ""}
            {!props.hidden ?
                <div className={"w100 hr-line"}/> : ""}
        </>)
    }
}